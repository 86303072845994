import React from 'react'
import './Booking.css';

function Booking() {
  return (
    <div className='container'>
    <iframe title='fortunaastrologyandtartot-booking' className='responsive-iframe' src="https://Fortunaastrologyandtarot.as.me/" width={1000}>
      
      </iframe>
    </div>

  )
}

export default Booking
