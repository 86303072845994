import React from 'react';
 import {Button} from './Button';
import './Footer.css';
import { Link } from 'react-router-dom';
import { InstagramEmbed } from 'react-social-media-embed';
 import CardItem from './CardItem'
import './Cards.css'

const today = new Date();
const year = today.getFullYear();

function Footer() {
  return (
    <div className='footer-container'>
    <section className='footer-subscription'>
      <p className='footer-subscription-heading'>What are you waiting for?</p>
      <p className='footer-subscription-text'>This is your sign
      <i className="fa-regular fa-moon"></i>
      </p>
   
      {/* <div className='input-areas'>
          <form>
              <input type="email" name='email' placeholder='Your Email' className='footer-input'/>
              <Button buttonStyle='btn--outline'>Subscribe</Button>  
          </form>
      </div> */}
    </section>
    <section>
    <div className='cards__container'>
        <div className='cards__wrapper'>
        <p className='footer-insta-heading'>Follow Me On Instagram</p>
            <ul className='cards__items ' >
          <InstagramEmbed className='footer-instagram-post' url="https://www.instagram.com/p/Cyi9ktExPYD/"  />
          <InstagramEmbed className='footer-instagram-post' url="https://www.instagram.com/p/CyoHW9hR6sS/" />
          <InstagramEmbed className='footer-instagram-post' url=" https://www.instagram.com/p/CyTevXzrHq8/"  />
            </ul>
        </div>
      </div>
  </section>
  <section>
    <div className='footer-links'>
      <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
              {/* <h2>Find your heart in the stars.</h2> */}
              <Link className='footer-link' to='/home'>Home</Link>
              <Link className='footer-link' to='/about'>About</Link>
              <Link className='footer-link' to='/services'>Services</Link>
              <Link className='footer-link' to='/contact'>Contact</Link>
              <section className='social-media'>
              <div className='social-media-wrap'>
                  <div className='social-icons'>
                    {/* <Link className='social-icon-link facebook'>
                    <i className='fab fa-facebook'/>
                    </Link> */}
                    <Link className='social-icon-link' to='https://www.instagram.com/fortuna11_23/'>
                    <i className='fab fa-instagram'/>
                    </Link>
                  </div>
              </div>
          </section>
          </div>

      </div>
    </div>
    </section>
  <section>
    <div className='footer-logo-section'> 
    <div className='footer-logo'>
          <Link to="/" className='social-logo'>
           <div className='footer-logo-text'> Fortuna Astrology & Tarot</div>
          </Link>
     </div>
   
     <small className='website-rights'>© {year} Fortuna Astrology & Tarot </small>
     </div>
  </section>

   </div>

 
  )
}

export default Footer
