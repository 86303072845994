import React from 'react'
import '../../App.css'
import ServicesSection from '../ServicesSection'

function Services() {
  return (
    <div>
      <ServicesSection></ServicesSection>
    </div>
  )
}

export default Services
