import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './Navbar.css';
import { Button } from './Button';



function Navbar() {
  const [click, setClick] = useState(false);
  const [ button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if(window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton([]);
  })

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className="navbar">
      <div className="navbar-container">
      <Link to="/" className="navbar-logo" onClick={handleClick}>
          <div className='fat-logo'>
            <div className='fat-logo-text'>
            Fortuna Astrology & Tarot
            </div>
          </div>
          &nbsp; Fortuna Astrology & Tarot
          </Link>

  
      <div className='menu-icon' onClick={handleClick}>
      <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
      </div>
      <ul className={click ? 'nav-menu active' : 'nav-menu'}>
      <li className='nave-item'>
      <Link to='/' className='nav-links' onClick={closeMobileMenu}>
        Home
      </Link>
      </li>
      <li className='nave-item'>
      <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
        About
      </Link>
      </li>
      <li className='nave-item'>
      <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
        Services
      </Link>
      </li>
      <li className='nave-item'>
      <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
        Contact
      </Link>
      </li>
      {/* <li className='nave-item'>
      <Link to='/sign-up' className='nav-links-mobile' onClick={closeMobileMenu}>
        Sign Up
      </Link>
      </li> */}
      </ul>   
      {/* {button && <Button  buttonStyle='btn--outline'>SIGN UP</Button>} */}
      </div>
      </nav>
    </>
  )
}

export default Navbar
