import React from 'react'
import '../App.css'
import './HomeDefault.css';
import {Button} from './Button';

function HomeDefault() {
    return (
        <div className='home-container'>
          <h1>Find your heart in the stars and the cards.</h1>
        <div className='home-btns'>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            >Book Now</Button> 
          </div>
        </div>
      )
}

export default HomeDefault
