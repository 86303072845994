import React from 'react';
import '../../App.css'
import HomeDefault from '../HomeDefault'
import Cards from '../Cards';

function Home () {
 return (
    <>
   <HomeDefault></HomeDefault>
   <Cards></Cards>
    </>

 )
}

export default Home; 