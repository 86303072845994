import React from 'react'
import EmailSender from '../EmailSender'
import '../../App.css'
import ContactSection from '../ContactSection'

function ContactPage() {
  return (
    <div>
        <ContactSection></ContactSection>
        <EmailSender></EmailSender>
    </div>
  )
}

export default ContactPage
