import React, { useState } from 'react';
import {Button} from './Button';
import './EmailSender.css'
import Popup from './Popup';


const EmailSender = () => {
  const [recipient, setRecipient] = useState('');
  const [body, setBody] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [posts, setPosts] = useState([]);
  const [isSending, setisSending] = useState(false);
  const [error, setError] = useState(null);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

   fetch("https://29kbhrc0w2.execute-api.us-east-1.amazonaws.com/ses-send-email", 
    {mode: "no-cors", 
    method: "POST",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        sourceEmailAddress: recipient,
        bodyText: body
    }),
    }
    )
    console.log(e);
    handleOpen(); 
    setRecipient('');
    setBody('');
    setInterval(() => {
        handleClose();
    }, 3000);
  }

      // Fetch data using Promise with the Fetch API
      const fetchSendEmailUsingPromiseWithFetchApi = () => {
        fetch("https://29kbhrc0w2.execute-api.us-east-1.amazonaws.com/ses-send-email", 
        {mode: "no-cors", 
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            sourceEmailAddress: recipient,
            bodyText: body
        }),
        }
        ) // Fetch data based on the current page
          .then((response) => response.json()) // Parse the response as JSON
          .then((data) => {
            setPosts(data); // Set the fetched data
            setError(null); // Clear any previous errors
            console.log(data);
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              console.log("Fetch aborted"); // Log a message when the request is intentionally aborted
              return; // Exit the function to prevent further error handling
            }
            setError(error.message); // Handle and set the error message
          })
          .finally(() => 
          setisSending(true),
          setInterval(() => {
            setisSending(false)
            setRecipient('')
            setBody('')
        }, 5000),); // Turn off loading indicator
      };
  

  return (
    <div className='email-container'>
        <div className='email-wrapper'>
         <h3>Please Enter email address and message below</h3>   
   
      <input required className='email-input'
        type="text"
        value={recipient}
        onChange={(e) => setRecipient(e.target.value)}
        placeholder="Your Email Address"
      />
      <textarea className='email-input email-textarea'
        value={body}
        onChange={(e) => setBody(e.target.value)}
        placeholder="Message"
      />
      <Button   className='btns send-email-btn'
   onClick={fetchSendEmailUsingPromiseWithFetchApi}>Send Email</Button>
    <Popup
            isOpen={isSending} handleClose={handleClose}
            >
      <h2>Email Sent Successfully</h2>
        
      </Popup>      
    </div>
    </div>
  );
};

export default EmailSender;