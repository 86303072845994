import React from 'react'
import '../../App.css'
import Booking from '../Booking'

function BookingPage() {
  return (
    <>
      <Booking></Booking>
    </>
  )
}

export default BookingPage
