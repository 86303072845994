import React from 'react';
import './AboutSection.css';

function AboutSection() {
  return (
    <div className='about-container'>
    </div>
  )
}

export default AboutSection
