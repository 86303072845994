import React from 'react'
import { Link } from 'react-router-dom';
import './NotFound.css'

function NotFound() {
  return (
    <div className='not-found-wrapper'>
      <h1>Oops! You seem to be lost.</h1>
            <p>Here are some helpful links:</p>
            <Link className='not-found-link' to='/'>Home</Link>
            <Link className='not-found-link' to='/services'>Services</Link>
            <Link className='not-found-link' to='/contact'>Contact</Link>
    </div>
  )
}

export default NotFound
