import React from 'react';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import AboutPage from './components/pages/AboutPage';
import Layout from './components/Layout';
import BookingPage from './components/pages/BookingPage';
import ContactPage from './components/pages/ContactPage';
import NotFoundPage from './components/pages/NotFoundPage';

const router = createBrowserRouter([ 
  {
    element: <Layout/>,
    
    children: [
      {path: '/', 
      element: <Home/>},
      {path: '/home', 
      element: <Home/>},
      {path: '/services', 
      element: <Services/>},
      {path: '/about', 
      element: <AboutPage/>},
      {path: '/booking',
      element: <BookingPage/>},
      {path: '/contact',
      element: <ContactPage/>},
      {path: '*',
      element: <NotFoundPage/>}
    ]
  }
  ])

function App() {
  return (
    <>
 <div>
         <RouterProvider router={router}/>
         </div>
    </>
  
  );
}

export default App;
