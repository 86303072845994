import React from 'react'
import './AboutInfo.css'
import { InstagramEmbed } from 'react-social-media-embed';
import { Button } from './Button';

function AboutInfo() {
  return (
    <div className='about-info-container'>
         
    <h1> About Me</h1>
      <h2>  Hi! I'm Christine Sweeney.</h2> 
      <div className='about-info-detail'>
        <h3>  Founder of Fortuna Astrology & Tarot. Astrology and Tarot are my greatest passions. </h3>
        <div class='instagram-intro-video'>
        <InstagramEmbed className='footer-instagram-post' url="https://www.instagram.com/p/Cyi9ktExPYD/"  />
        </div>    
       <p className='about-info-p'> Astrology for me is like a little blueprint to our lives which I use to help answer questions as to what our overall purpose in life is.
        When I was a child I inherited a tarot deck and I've been reading tarot almost my whole life. 
        In the last 4 years I've really embraced my spirituality, intution, and I've become much more confident in being able to read tarot and birth charts.
        I feel this is part of my purpose and that I have these skills to help guide people.</p>
        <p className='about-info-p'>I consider myself a spirit worker, and I want to help people find thier way in this crazy life. I do this for my friends and family and would love the opportunity to help you as well.</p>
        <p className='about-info-p'>I can do basic birth readings or I can include transit readings, progressions, or solar return charts for a more complex reading.
        All I need is birth place, birth time, and birth location.</p>
        <h3>I look forward to helping you find your way.</h3>
        </div>
        <Button path='/services' buttonStyle='btn--primary' buttonSize='btn--large'>View Services</Button>
        <br></br>
        <Button path='/booking' buttonStyle='btn--primary' buttonSize='btn--large'>Book Now</Button>
        <br></br>
    </div>
  )
}

export default AboutInfo
