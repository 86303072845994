import React from 'react'
import '../App.css'
import './ServicesSection.css';
import './Cards.css'
import {Button} from './Button';

function ServicesSection() {
  return (
    <div className='cards'>
       <div className='cards__container'>
     <h1>Astrological Services</h1> 
<table class="responsive">
    <thead>
        <tr>
            <th>Service</th>
            <th>Descirption</th>
            <th>Price</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Full birth chart reading - 1 hour</td>
            <td >We will discuss your birth chart, any applicable questions, and any standout transits.</td>
            <td>$75</td>
               <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
         
        </tr>
        <tr>
            <td>Year ahead reading - 1 hour</td>
            <td>We will go over transits, secondary progressions, and your solar return chart to see what the year has in store for you.</td>
            <td>$100</td>
            <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
        </tr>
        <tr>
            <td>Full Birth Chart Reading and Year Ahead Reading Package - 2 hours</td>
            <td>A package deal where we look at your overall birth chart and the year ahead.</td>
            <td>$150</td>
            <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
        </tr>
        <tr>
            <td>Synastry Reading - 1.5 hours</td>
            <td>This reading shows the astrology of relationships. If you have a romantic partner, friend, or family member that you would like to explore more about your relationship dynamics, pick this reading.</td>
            <td>$150</td>
            <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
        </tr>
        <tr>
            <td>Death Chart Reading - 1 hour</td>
            <td>I can look at a loved ones birth chart at their time of death to help answer any questions
you may have and help give closure in the grieving period.</td>
            <td>$75</td>
            <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
        </tr>
        <tr>
            <td>Chart Rectification</td>
            <td>If you do not know your birth time, I can “rectify” your chart using major life events. I
need ideally 7-10 major life events in a like marriage, divorce, birth of children, death in the
family, illness, major job events, etc. I match these events with major transits in your life to
figure out your probable rising sign and degree.
I am still gaining experience in the following, and have priced readings accordingly to get more
experience in these areas that I am fascinated with. These readings would still be either in
person or over zoom, with the exception of horary readings.
</td>
   <td>$225</td>
   <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
        </tr>

        <tr>
            <td>Fertility reading</td>
            <td>Disclaimer: I am not a licensed health practitioner. The birth chart can show windows of
opportunity for fertility or having children.</td>
            <td>$50</td>
            <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
        </tr>
        <tr>
            <td>Health Question Reading</td>
            <td>Disclaimer: I am not a licensed health practitioner. The birth chart can answer questions
about health, illness, injury, or sometimes point us in the right direction for misdiagnosis or hard
to diagnose issues.</td>
            <td>$50</td>
            <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
        </tr>
        <tr>
            <td>Horary Question - Email Only</td>
            <td>
            Horary Astrology is a technique in astrology that uses the time and date that a question is recieved by the astrologer to draw up a "birth chart" for that question. The astrologer determines an answer to the question based on that chart. I give the answer via email only in a detailed written response.

            </td>
            <td>$30</td>
            <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
        </tr>
        <tr>
            <td>Electional Astrology - email only</td>
            <td>This is a technique that allows us to best work with the astrological energies for large
events in our lives that we want to go well. Marriage, businesses, surgeries, etc are common
elections. I would choose the best date and time based on the time restraints given for an event.</td>
            <td>$75</td>
            <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
        </tr>
    </tbody>
</table>
<h1>Tarot Services</h1>
<table class="services-table">
    <thead>
        <tr>
            <th>Service</th>
            <th>Price</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
    <tr>
            <td>Full spread - 30 minute tarot card reading</td>
            <td>$40</td>
            <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
        </tr>
        <tr>
            <td>Full spread - 45 minute tarot card reading</td>
            <td>$55</td>
            <td>
              <div className='btn-containter'>  
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </div> </td>
        </tr>
        <tr>
            <td>Year ahead tarot reading - 1 hour</td>
            <td>$75</td>
            <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
        </tr>
        <tr>
            <td> 1 Card Question (email only)</td>
            <td>$20</td>
            <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
        </tr>
        <tr>
            <td> 3 Card Question (email only)</td>
            <td>$30</td>
            <td>
            <Button 
            path="/Booking"
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            >Book Now
            </Button> </td>
        </tr>

    </tbody>
</table>
</div> 
    </div>
 
    
  )
}

export default ServicesSection
