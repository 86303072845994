import React from 'react'
import NotFound from '../NotFound'
function NotFoundPage() {
  return (
    <div>
      <NotFound></NotFound>
    </div>
  )
}

export default NotFoundPage
