import React from 'react'
import CardItem from './CardItem'
import './Cards.css'



function Cards() {
  return (
    <div className='cards'>
        <h1> Welcome, to Fortuna Astrology & Tarot!</h1>
          <h2>  Hi! I'm Christine Sweeney. I read birth charts and do tarot readings.
            I can do basic birth readings or I can include transit readings, progressions, or solar return charts for a more complex reading.
            All I need is birth place, birth time, and birth location. I look forward to helping you find your way.</h2>
      <div className='cards__container'>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
            <CardItem src='/images/natal-chart-aquarius-planet-saturn.jpg' 
            text='Birth charts are like blue prints for our life. The position of the planets at the time of birth can answer big life questions. See what the planets are saying about you by booking a birth chart reading.' 
            label='Birth Chart Reading' 
            path="/services"></CardItem>
              <CardItem src='/images/tarot-card-reading.jpg' 
            text='Tarot is an intuitive art form where my spirit guides help me give needed messages. Get your questions answered with a Tarot Reading.' 
            label='Tarot Reading' 
            path="/services"></CardItem>
             <CardItem src='/images/crystal-ball.jpg' 
            text='What is my purpose? What would be a good career for me? What is destined for me in love and romance? Allow me to help you get the answers to these questions or any other burning philosophical questions you have on your journey.' 
            label='Questions?' 
            path="/services"></CardItem>
            </ul>
        </div>
      </div>
    </div>
  )
}

export default Cards
