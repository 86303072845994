import React from 'react'
import './ContactSection.css'


function ContactSection() {
  return (
    <div className='contact-info-container'>
   
    <h1> Contact Info</h1><br></br>
   
     <h2>Christine Sweeney</h2>
     <h2>christine.sweeney@fortunaastrologyandtarot.com</h2><br></br>
     {/* <h1> Contact Me</h1><br></br> */}
    
    </div>
  )
}

export default ContactSection
