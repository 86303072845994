import React from 'react';
import '../../App.css';
import AboutSection from '../AboutSection'
import AboutInfo from '../AboutInfo';

function AboutPage() {
  return (
    <>
      <AboutSection>
      </AboutSection>
      <AboutInfo></AboutInfo>
    </>
  )
}

export default AboutPage
